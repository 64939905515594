import '/assets/styles/pages/category.scss';

import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import kebabCase from 'lodash/kebabCase'
import customListFilters from '/data/listing-filters/types/customlist'

// Services
import productServices from '/services/product';

// Functions
import isExact from 'pstv-commerce-tools/functions/is-exact';

// Sections
import ListingHead from '/views/sections/listing-head';
import Listing from '/views/sections/listing';
import Breadcrumbs from '/views/sections/breadcrumbs';

// Context
import { useNavigator } from 'pstv-commerce-tools/utilities/navigator'
import { useHead } from 'pstv-commerce-tools/utilities/head';
import { ListingProvider, decodeQueryString, useListing } from 'pstv-commerce-tools/utilities/listing';
import ListingFilters from '../sections/listing-filters';

const getCustomListProducts = (customListID, query) => {
	return productServices.getCustomListProducts(customListID, query)
}

const getCustomListFilters = (customListID, query) => {
	return productServices.getCustomListFilters(customListID, query)
}

const parseHead = (customList) => {
	const title = customList.seo_title || customList.title;
	return {
		title: title,
		description: customList.seo_description || `${title} ürünleri.`,
	}
}

const defaultQuery = {
	page: '1',
	// order: 'ovd',
}

const CustomlistQueryProvider = ({match, ...props}) => {
	return (
		<ListingProvider
			externalParams={{ customListID: match?.params?.id }}
			availableFilters={customListFilters}
			initialFilterData={props.pageProps?.filters}
			queryString={match?.params?.query}
			defaultQuery={defaultQuery}>
			<CustomList {...props} />
		</ListingProvider>
	)
}

const CustomList = ({ pageProps }) => {
	const { inputQuery, outputData, setFilterData } = useListing();
	const { redirect } = useNavigator();

	const { setHead } = useHead();
	const [{products, pagination, fetchedQuery, customList}, setProducts] = useState(pageProps?.productData ?? {products: null, fetchedQuery: null, pagination: null, customList: null});

	useEffect(() => {
		if(customList) {
			setHead(parseHead(customList));
		}
	}, [customList])

	useEffect(() => {
		if(inputQuery.customListID && !isExact(fetchedQuery, inputQuery)) {
			const { customListID, ...queryParams } = inputQuery;
			setProducts({ products: null, pagination: null, fetchedQuery: inputQuery });
			getCustomListProducts(customListID, queryParams).then(productData => { setProducts({ ...productData, fetchedQuery: inputQuery}) });
			getCustomListFilters(customListID, queryParams).then(filterData => {
				setFilterData(filterData);
			});
		}
	}, [inputQuery, fetchedQuery])

	useEffect(() => {
		if(customList && customList.id.toString() === outputData?.externalParams?.customListID) {
			const params = {
				id: outputData.externalParams.customListID,
				slug: customList.url ?? kebabCase(customList.name)
			};
	
			if(outputData.queryString && outputData.queryString.length) {
				params.query = outputData.queryString;
			}
			redirect('customList', params)
		}
	}, [outputData, customList])

	return (
		<>
			<Breadcrumbs data={customList ? [{ name: customList.title }] : undefined} />
			<ListingHead title={customList?.title} />
			<ListingFilters />
			<Listing
				products={products}
				pagination={pagination} />
		</>
	)
}

CustomList.propTypes = {
	pageProps: PropTypes.object
}

CustomlistQueryProvider.getServerProps = ({ match }) => {
	return new Promise((resolve) => {
		const rawQuery = decodeQueryString({
			availableFilters: customListFilters,
			queryString: match.params?.query,
			defaultQuery,
			externalParams: { customListID: match.params.id }
		});

		const { customListID, ...query } = rawQuery;
		Promise.all([
			getCustomListProducts(customListID, query),
			getCustomListFilters(customListID, query),
		]).then(([productData, filters]) => {
			resolve({
				pageProps: {
					filters,
					productData: {
						...productData,
						fetchedQuery: rawQuery,
					},
				},
				head: parseHead(productData.customList),
			})
		}).catch(() => {
			resolve({
				status: 500,
			})
		})
	})
}

CustomlistQueryProvider.propTypes = {
	match: PropTypes.object,
	pageProps: PropTypes.object,
}

export default CustomlistQueryProvider
