const defaultFilter = {
	isFilter: true,
	filterProcessor: 'default', // filtre verisinin ne şekilde işlenip filtre modalına aktarılacağını belirler
	title: 'Filtre', // filtre başlığı
	valueKey: 'id', // filtre verisinin value alanının hangi field'dan geleceğini belirler
	labelKey: 'name', // filtre verisinin label (metin) alanının hangi field'dan geleceğini belirler
	valueFormat: 'string', // query string'den gelen value alanının formatı.
	affectedFields: [], // query'nin filtre içerisinde etkileşim kurduğu ek fieldlar (temizleme işlemleri için kullanılır)
}

export default defaultFilter